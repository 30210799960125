/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import Special from '../components/Special';
import ContactUs from '../components/ContactUs';

// markup
function NotFoundPage({ path }) {
  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
          gatsbyImageData(layout: FIXED, width: 112, )
        }
      }

    }
  `);
  return (
    <>
      <SEO />
      <div className="flex flex-col min-h-full pt-16 pb-12 bg-white">
        <section className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center flex-shrink-0">
            <a href="/" className="inline-flex">
              <span className="sr-only">Yuan Yen Do Self Defense</span>
              <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Yuan Yen Do Self Defense" />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold tracking-wide uppercase text-primary">404 error</p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Page not found.</h1>
              <p className="mt-2 text-base text-gray-500">Sorry, we couldn&rsquo;t find the page you&rsquo;re looking for.</p>
              <div className="mt-6">
                <Link to="/" className="text-base font-medium text-secondary hover:text-secondary-light">
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Special path={path} />
      <ContactUs />
    </>
  );
}

export default NotFoundPage;
