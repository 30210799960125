import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GlobeIcon } from '@heroicons/react/outline';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function ContactUs() {
  const { schools, tiger } = useStaticQuery(graphql`
  query {
    tiger: file(relativePath: {eq: "resized-tiger.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 235, height: 300)
        }
      }
    
    schools: allSanitySchool {
        nodes {
          name
          address {
            city
            state
            street
            zip
          }
          phone
          _id
          slug {
            current
          }
        }
    }
  }
`);
  return (
    <section className="py-16 bg-contain bg-background-light sm:px-6 lg:px-16" id="contact-us">
      <h2 className="pb-8 text-4xl font-extrabold tracking-tight text-center text-gray-900 sm:text-5xl filter drop-shadow-md">
        <span className="block text-blue-900">Contact Us</span>
      </h2>
      <div className="px-4 mx-auto mt-4 xl:space-x-20 lg:space-x-10 max-w-7xl lg:flex lg:items-start lg:justify-center">

        <div className="text-xl text-center lg:text-right">
          <h4 className="py-2 text-3xl font-extrabold text-primary">{schools.nodes[0].name}</h4>
          <p className="text-2xl font-semibold">{schools.nodes[0].phone}</p>
          <p>{schools.nodes[0].address.street}</p>
          <p>{schools.nodes[0].address.commet}</p>
          <p>
            {schools.nodes[0].address.city}
            {' '}
            {schools.nodes[0].address.state}
            {' '}
            {schools.nodes[0].address.zip}
          </p>
          <div className="inline-flex m-8 rounded-md shadow">
            <Link
              to={`/school/${schools.nodes[0].slug.current}`}
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
            >
              <GlobeIcon className="w-6 h-6 pr-2" />
              Get Directions
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center flex-shink-0 lg:block">

          <GatsbyImage
            className=""
            image={tiger.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do Tiger"
          />
        </div>
        <div className="text-xl text-center lg:text-left">
          <h4 className="py-2 text-3xl font-extrabold text-primary">{schools.nodes[1].name}</h4>
          <p className="text-2xl font-semibold">{schools.nodes[1].phone}</p>
          <p>{schools.nodes[1].address.street}</p>
          <p>{schools.nodes[1].address.commet}</p>
          <p>
            {schools.nodes[1].address.city}
            {' '}
            {schools.nodes[1].address.state}
            {' '}
            {schools.nodes[1].address.zip}
          </p>
          <div className="inline-flex mx-6 my-8 rounded-md shadow">
            <Link
              to={`/school/${schools.nodes[1].slug.current}`}
              className="inline-flex items-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md justify-left bg-secondary hover:opacity-90"
            >
              <GlobeIcon className="w-6 h-6 pr-2" />
              Get Directions
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
