import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { CheckIcon } from '@heroicons/react/outline';
import { PhoneIcon } from '@heroicons/react/solid';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export default function Special({ path }) {
  const { kick } = useStaticQuery(graphql`
        query {
          kick: file(relativePath: { eq: "karate-kick.png" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      `);
  return (
    <section className="relative z-10 px-4 py-12 bg-right bg-cover shadow-lg shadow-inner md:px-16 bg-diamond bg-primary md:py-16 xl:py-24" id="introductory-special">
      <div className="container grid grid-cols-6 mx-auto justify-items-end">
        <div className="hidden md:block md:col-span-2">
          <div className="relative z-0 w-64 h-96">
            <GatsbyImage
              className="w-full h-full -rotate-[10deg]"
              image={kick.childImageSharp.gatsbyImageData}
              alt="Shadow Karate Kick"
            />
          </div>
        </div>
        <div className="col-span-6 text-center text-white md:col-span-4 lg:text-left">
          <h3 className="mb-2 text-3xl font-black tracking-tight sm:text-3xl lg:text-4xl xl:text-5xl filter drop-shadow-lg text-secondary">
            $29.95 Introductory Special
          </h3>
          <p className="pl-4 text-xl lg:text-2xl xl:text-3xl">Schedule yours today</p>
          <ul className="my-4 md:ml-12 lg:text-lg xl:text-xl">
            <li className="flex justify-center lg:justify-start">
              <CheckIcon className="w-6 h-6 mr-4 text-green-500" aria-hidden="true" />
              Try two Karate Classes
            </li>
            <li className="flex justify-center lg:justify-start">
              <CheckIcon className="w-6 h-6 mr-4 text-green-500" aria-hidden="true" />
              Receive a Free Uniform
            </li>
          </ul>
          <div className="max-w-sm mx-auto mt-10 md:ml-16">
            <AnchorLink
              to={`${path}#contact-us`}
              className="flex items-center justify-center py-3 pl-2 pr-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
            >
              <PhoneIcon className="w-6 h-6 pr-2" />
              Contact Us
            </AnchorLink>
          </div>
          <p className="mt-8 lg:text-lg xl:text-xl">At Yuan Yen Do, your son or daughter will be trained in the art of self defense while developing peak performance through focus, self control &amp; discipline! </p>
        </div>
      </div>
    </section>
  );
}
